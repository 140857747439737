@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,400;0,500;1,100;1,200;1,400;1,500&display=swap";
.BackgroundImage {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  position: fixed;
}

@media (min-width: 600px) {
  .footer a {
    display: inline-block;
  }
}

@media (max-width: 600px) {
  .footer a {
    text-align: center;
    flex-direction: column;
    padding: 10px;
    display: flex;
  }
}

.footer svg:hover path {
  color: #e4ff1a;
}

.intro {
  padding: 2.5%;
}

h1, a {
  font-size: 40px;
}

@media (min-width: 600px) {
  .container {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .intro {
    text-align: right;
    height: 50vh;
    flex-direction: column;
    padding-top: 150px;
    display: flex;
  }
}

@media (max-width: 600px) {
  .container {
    justify-content: center;
    padding-top: 125px;
    display: flex;
  }

  .intro {
    text-align: center;
    flex-direction: column;
    display: flex;
  }
}

.container {
  padding: 3rem;
}

.links {
  padding: 1rem;
}

li {
  text-align: center;
  padding: 10px;
  font-size: 40px;
  text-decoration: none;
  list-style-type: none;
}

svg {
  vertical-align: middle;
  margin-top: -5px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

*, :before, :after {
  box-sizing: border-box;
  color: #cacaca;
  font-family: IBM Plex Sans Condensed, sans-serif;
  font-weight: 400;
}

html {
  color: #cacaca;
  background-color: #141414;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

/*# sourceMappingURL=index.ea4e12cc.css.map */
