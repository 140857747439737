.BackgroundImage {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vh;
}

body {
	position: fixed;
}
