li {
  font-size: 40px;
  text-decoration: none;
  list-style-type: none;
  list-style-type: none;
  padding: 10px;
  text-align: center;
}

svg {
  vertical-align: middle;
  margin-top: -5px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
